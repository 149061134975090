import {
  Container,
  FeaturedVideo,
  FeaturedVideoProps,
  Helmet,
  Layout,
  LinearBorder,
  Section,
  Theme
} from "@life-without-barriers/react-components"
import {
  ContentNodes,
  PageResources,
  Resource,
  Site,
  firstNodes
} from "@life-without-barriers/gatsby-common"

import AboriginalAndTorresStraitIslanderPeoplesBanner from "../../../../components/social-policy/AboriginalAndTorresStraitIslanderPeoplesBanner"
import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { graphql } from "gatsby"
import { renderFeaturedResources } from "../../../../components/social-policy/contentRenderer"

const { aboriginalAndTorresStraitIslanderPeoplesThemeFull } = Theme

interface SocialJusticePageProps {
  location: { pathname: string }
  data: {
    openGraphImage: IGatsbyImageData
    bannerImage: IGatsbyImageData
    promoImage: IGatsbyImageData
    site: Site
    pageResources: PageResources
    uluruStatementResource?: ContentNodes<Resource>
    treatyResource?: ContentNodes<Resource>
    referendumResource?: ContentNodes<Resource>
  }
}

interface IntroductionSectionProps {
  children: string
}

const IntroductionSection = ({ children }: IntroductionSectionProps) => (
  <Container>
    <LinearBorder>{children}</LinearBorder>
  </Container>
)

const FeaturedVideoSection = (props: FeaturedVideoProps) => (
  <Section background="bg-lwb-theme-xxx-light">
    <Container>
      <FeaturedVideo {...props} />
    </Container>
  </Section>
)

const SocialJusticePage = ({
  location,
  data: {
    site: { siteMetadata },
    openGraphImage,
    uluruStatementResource,
    treatyResource,
    referendumResource,
    bannerImage
  }
}: SocialJusticePageProps) => (
  <div>
    <Helmet
      title={`Social Justice - Where we stand on reconciliation | ${siteMetadata.title}`}
      description="Find out where we stand on major issues affecting Aboriginal and Torres Strait Islander Peoples"
      siteUrl={siteMetadata.siteUrl}
      path={location.pathname}
      image={openGraphImage}
    />
    <Layout theme={aboriginalAndTorresStraitIslanderPeoplesThemeFull}>
      <AboriginalAndTorresStraitIslanderPeoplesBanner
        title="Social justice"
        bannerImage={bannerImage}
      />
      <IntroductionSection>
        Life Without Barriers believes reconciliation must live in the hearts
        and minds of all Australians. As a nation, we need to work together to
        close the gap in life expectancy by improving the cultural, spiritual
        and emotional well-being of Aboriginal and Torres Strait Islander
        peoples.
      </IntroductionSection>
      {renderFeaturedResources({
        title: "Position statements",
        resources: firstNodes([
          uluruStatementResource,
          referendumResource,
          treatyResource
        ])
      })}
      <FeaturedVideoSection
        videoId="I8U3x55VqSM"
        title="Uluru Statement from the Heart"
        description={{
          kind: "plain",
          value: "Hear Life Without Barriers staff read the Statement."
        }}
        videoPosition="left"
      />
    </Layout>
  </div>
)

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    openGraphImage: file(relativePath: { regex: "/social-justice-og.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    bannerImage: file(relativePath: { regex: "/social-justice-banner.png/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    uluruStatementResource: allContentfulResource(
      filter: {
        title: {
          regex: "/Uluru Statement from the Heart - Position Statement/i"
        }
      }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    treatyResource: allContentfulResource(
      filter: { title: { regex: "/Victoria Treaty - Statement of Support/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    referendumResource: allContentfulResource(
      filter: { title: { regex: "/referendum/i" } }
    ) {
      edges {
        node {
          ...ContentfulResource
        }
      }
    }
    pageResources: allContentfulPageResources(
      filter: {
        title: {
          eq: "aboriginal-and-torres-strait-islander-people-social-justice"
        }
      }
    ) {
      edges {
        node {
          title
          pageImages {
            ...SmallRemoteContentfulAsset
          }
        }
      }
    }
  }
`

export default SocialJusticePage
